<template>
  <div>
    <v-app-bar elevate-on-scroll class="header" app flat>
      <v-avatar size="45" class="ml-15">
        <v-img width="1" src="img/logo.png" />
      </v-avatar>
      <v-app-bar-title class="ml-5 font-weight-bold"
        >LVCT</v-app-bar-title
      >
      <v-spacer></v-spacer>
      <div v-if="number != 0" class="mr-7">
          <v-badge light overlap color="red" :content="number">
            <v-icon dark class="universal--text"> mdi-bell </v-icon>
          </v-badge>
        </div>
        <div v-else class="mr-7">
          <v-icon dark class="universal--text"> mdi-bell </v-icon>
        </div>
      <div align="right" class="mr-15 hidden-sm-and-down">
        <v-layout row wrap>
          <v-flex xs2>
            <v-avatar size="45" class="brown mr-3">
              <v-icon class="white--text">mdi-account</v-icon>
            </v-avatar>
          </v-flex>
          <v-flex xs10>
            <v-layout column>
              <v-flex xs12 class="mt-1 font-weight-bold"> 
                <div align="left" class="ml-7">
                Mr. Lenny
                </div>
                </v-flex>
              <v-flex xs12> Lenny@mail.com </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </v-app-bar>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import Vue from "vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapState, mapActions } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: true,
      number: 5
    };
  },
  watch: {
    darkState(oldval, newval) {
      this.handledarkmode();
    },
  },
  created() {
    this.initialize();
  },
  // mounted(){
  //   this.$store.dispatch(USER_REQUEST);
  // },
  methods: {
    ...mapActions(["changeDarkState", "changeLanguage"]),
    signOut: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/"));
    },
    initialize() {
      this.languageChoice = this.language;
    },
    handledarkmode() {
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
    changeLocale() {
      i18n.locale = this.languageChoice;
      this.changeLanguage(this.languageChoice);
    },
  },
  computed: {
    ...mapGetters(["getProfile", "darkState", "language"]),
  },
};
</script>