import Vue from 'vue'
import Vuex from 'vuex'

import language from './modules/language'

import role from './modules/role'
import users from './modules/users'
import permission from './modules/permission'
import user from './modules/user'
import auth from './modules/auth'

import abilities from './modules/abilities'
import loginCard from './modules/loginCard'
import darkMode from './modules/darkMode'

import location from './modules/location'
import institution from './modules/institution'
import level from './modules/level'
import levelstream from './modules/levelstream'
import staff from './modules/staff'
import student from './modules/students'

import VuexPersist from 'vuex-persist'
import { abilityPlugin, ability as appAbility } from './ability'

Vue.use(Vuex)

export const ability = appAbility

const vuexLocalStorage = new VuexPersist({
  key: 'unhcr_app', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: mutation => (true)
})

export default new Vuex.Store({
  plugins: [
    vuexLocalStorage.plugin,
    abilityPlugin
  ],

  modules: {
    language,

    role,
    users,
    user,
    auth,
    permission,
    abilities,
    loginCard,
    darkMode,

    location,
    institution,
    level,
    levelstream,
    staff,
    student,
  },
  strict: false,
})