import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import StaffIcon from '../components/icons/StaffIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        //dark: true,
        themes: {
            light: {
                primary: '#976eb0',
                secondary: '#0f1e3d',
                accent: '#dacce3',
                error: '#b71c1c',
                background: "#f5f6fa",
                header: "#ffffff",
                button: "EEEEEE",
                universal: '#976eb0',
                nav: '#976eb0',
            },
            dark: {
                primary: '#976eb0',
                secondary: '#976eb0',
                accent: '#D21F26',
                error: '#b71c1c',
                background: "#121212",
                header: "#272727",
                button: "292929",
                universal: '#976eb0',
                nav: '#0f1e3d',
            },
        },
    },
    icons:{
        values:{
            staff:{
                component: StaffIcon,
            }
        }
    }
});
