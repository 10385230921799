import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/redirector')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const routes = [
  // {
  //   path: '/roles',
  //   name: 'Roles',
  //   component: require('@/views/accessControl/role.vue').default,
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: '/users',
  //   name: 'Users',
  //   component: require('@/views/accessControl/user.vue').default,
  //   beforeEnter: ifAuthenticated,
  // },
  
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
  },
//   {
//     path: "/redirector",
//     name: "Redirector",
//     component: require("@/views/Redirector.vue").default,
//     beforeEnter: ifAuthenticated,
// },
//   {
//     path: '/admin-home',
//     name: 'AdminHome',
//     component: () => import(/* webpackChunkName: "about" */ '../views/adminHome.vue'),
//     beforeEnter: ifAuthenticated,
//   },
//   {
//     path: '/staff-home',
//     name: 'StaffHome',
//     component: () => import(/* webpackChunkName: "about" */ '../views/staffHome.vue'),
//     beforeEnter: ifAuthenticated,
//   },
//   {
//     path: '/locations',
//     name: 'Location',
//     component: () => import(/* webpackChunkName: "about" */ '../views/location/index.vue'),
//     beforeEnter: ifAuthenticated,
//   },
//   {
//     path: '/institutions',
//     name: 'Institution',
//     component: () => import(/* webpackChunkName: "about" */ '../views/institution/index.vue'),
//     beforeEnter: ifAuthenticated,
//   },
//   {
//     path: '/classes',
//     name: 'Class',
//     component: () => import(/* webpackChunkName: "about" */ '../views/level/index.vue'),
//     beforeEnter: ifAuthenticated,
//   },
//   {
//     path: '/class-streams',
//     name: 'Class Stream',
//     component: () => import(/* webpackChunkName: "about" */ '../views/levelstream/index.vue'),
//     beforeEnter: ifAuthenticated,
//   },
//   {
//     path: '/staff',
//     name: 'Staff',
//     component: () => import(/* webpackChunkName: "about" */ '../views/staff/index.vue'),
//     beforeEnter: ifAuthenticated,
//   },
//   {
//     path: '/students',
//     name: 'Student',
//     component: () => import(/* webpackChunkName: "about" */ '../views/students/index.vue'),
//     beforeEnter: ifAuthenticated,
//   },
]

const router = new VueRouter({
  routes
})

export default router
